<template>
  <section class="contacts">
    <div class="container">
      <div class="contacts__body">
        <h1 class="contacts__title">Контакты</h1>
        <div class="contacts__map">
          <div class="contacts__map-container">
            <tk-ymaps
                :coords="coords"
                :is-point="true"
                :map-icon="{ src: '/images/map.svg', size: [42, 48] }"
            >
            </tk-ymaps>
          </div>
        </div>
        <h2 class="contacts__subtitle">
          Наши контакты
        </h2>
        <div class="contacts__items">
          <div class="contacts__item">
            <p>Адрес офиса:</p>
            <div>
              <p>г. Москва, Большой бульвар, д. 42, стр. 1, Технопарк “Сколково”</p>
            </div>
          </div>
          <div class="contacts__item">
            <p>Email:</p>
            <div>
              <a href="mailto:teremok.skolkovo@gmail.com">teremok.skolkovo@gmail.com</a>
            </div>
          </div>
          <div class="contacts__item">
            <p>Telegram:</p>
            <div>
              <a href="https://t.me/glitch999999">@glitch999999</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TkYmaps from "@/components/global/tkYmaps";

export default {
  name: "Contacts",
  components: { TkYmaps },
  data() {
    return {
      coords: [55.688088, 37.368716]
    }
  },
}
</script>

<style lang="scss">
.contacts{
  margin-bottom: 150px;
  &__title{
    margin: 40px 0;
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
  }
  &__map{
    height: 400px;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 45px;
    overflow: hidden;
  }
  &__map-container{
    width: 100%;
    height: 100%;
    overflow: hidden;
    & > div {
      height: 100%;
    }
  }
  &__subtitle{
    margin: 0 0 28px 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #16142B;
  }
  &__items{
    display: grid;
    grid-template-columns: 4fr 3fr 4fr 3fr;
    gap: 70px;
  }
  &__item{
    & > p{
      line-height: 26px;
      color: #626170;
      margin: 0 0 15px 0;
    }
    div{
      font-size: 18px;
      line-height: 30px;
      color: #16142B;
      display: flex;
      flex-direction: column;
      gap: 10px;
      p{
        margin: 0;
      }
      a{
        color: #1AC386;
      }
    }
  }
  @media screen and (max-width: 1151px){
    &__items{
      grid-template-columns: 1fr 1fr;
      row-gap: 40px;
      column-gap: 40px;
    }
  }
  @media screen and (max-width: 479px){
    &__title{
      margin: 25px 0;
      font-size: 22px;
      line-height: 32px;
    }
    &__items{
      grid-template-columns: 1fr;
      gap: 25px;
    }
  }
  }
</style>
